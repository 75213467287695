import React, { useCallback, useState, useRef, useEffect, useMemo } from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import cx from "classnames";

import history from "../../../core/history";

import styles from "./TabsPicker.scss";

// interface TabsPickerProps {
//   queryStringName: string;
//   tabs: Tab[],
//   variant: 'underlined' | 'outlined'
// }

// interface Tab {
//   tabName: string;
//   value: string;
//   component: React.child
// }

function TabsPicker(
  { queryStringName = "tabName", tabs = [], variant = "underlined", switchToBaseUrl = false, baseUrl ='' },
  { searchParams }
) {
  const location = useLocation();
  const [prevTabValue, setPrevTabValue] = useState(null);
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const [isTabBorderHidden, setIsTabBorderHidden] = useState(false)
  const tabsContainerRef = useRef(null);

  function getQueryParam(key) {
    if(switchToBaseUrl) return undefined;
    const searchParams = new URLSearchParams(location?.search);
    return searchParams?.get(key);
  }

  const activeTabValue = useMemo(() => {
    return (
      getQueryParam(queryStringName) ||
      searchParams[queryStringName] ||
      tabs[0].value
    );
  }, [getQueryParam, searchParams, queryStringName, tabs]);
  
  const activeTab = useMemo(() => {
    return tabs.find(tab => tab.value === activeTabValue);
  }, [tabs, activeTabValue]);

  function handleTabChange(tabValue) {
    if (activeTabValue !== tabValue) {
      setPrevTabValue(activeTabValue);
      if(switchToBaseUrl) history.push(`${baseUrl}?${queryStringName}=${tabValue}`)
      else history.setSearchParams({ [queryStringName]: tabValue });
    }
  }

  useEffect(() => {
    if (tabsContainerRef.current && variant === "underlined") {
      const prevTabIndex = tabs.findIndex(tab => tab.value === prevTabValue);
      const activeTabIndex = tabs.findIndex(
        tab => tab.value === activeTabValue
      );
      const prevTabEl = tabsContainerRef.current.children[prevTabIndex];
      const activeTabEl = tabsContainerRef.current.children[activeTabIndex];

      const containerRect = tabsContainerRef.current.getBoundingClientRect();

      if (prevTabEl && activeTabEl) {
        setIsTabBorderHidden(true)
        const prevRect = prevTabEl.getBoundingClientRect();
        const activeRect = activeTabEl.getBoundingClientRect();

        setIndicatorStyle({
          transform: `translateX(${prevRect.left -
            containerRect.left}px) scaleX(${prevRect.width / 10})`,
          transition: "none",
          visibility: 'hidden',
        });

        requestAnimationFrame(() => {
          setIndicatorStyle({
            visibility: 'visible',
            transform: `translateX(${activeRect.left -
              containerRect.left}px) scaleX(${activeRect.width / 10})`,
            transition: "transform 0.3s ease-in-out",
          });
          setTimeout(()=>setIsTabBorderHidden(false),300)
        });
        setPrevTabValue(null)
      }
    }
  }, [activeTabValue, tabsContainerRef.current]);

  const renderTabs = useCallback(() => {
    return (
      <div className={cx(styles.tabsContainer,styles[variant],isTabBorderHidden && styles.isTabBorderHidden)} ref={tabsContainerRef}>
        {tabs.map(({ tabName, value }, index) => (
          <button
            className={cx(
              styles.tab,
              activeTabValue === value && styles.active
            )}
            key={index}
            onClick={() => handleTabChange(value)}
          >
            <span>{tabName}</span>
          </button>
        ))}
        {variant === "underlined" && isTabBorderHidden && (
          <div className={styles.tabIndicator} style={indicatorStyle} />
        )}
      </div>
    );
  }, [tabs, activeTabValue, indicatorStyle, isTabBorderHidden]);

  return activeTab
    ? React.cloneElement(activeTab.component, { renderTabs })
    : null;
}

TabsPicker.contextTypes = {
  searchParams: PropTypes.object
};

export default withStyles(styles)(TabsPicker);
